<script>
    // import "leaflet/dist/leaflet.css";
    import { fly } from "svelte/transition";
    // import { swipe } from "svelte-gestures";

    // const manualPad = 0.01 * window.innerHeight;
    const dateOptions = { day: "numeric", month: "long" };
    const formatter = new Intl.DateTimeFormat("en-US", dateOptions);
    const cardHeightDefault = 0.32 * window.innerHeight;
    const cardHeightMin = cardHeightDefault;
    const cardHeightMax = 0.72 * window.innerHeight;

    let showing = false;
    // let extended = false;
    let currentPin;
    let card;
    let button;
    let buttonHeight;

    // State
    let isDragging = false,
        cardHeight = cardHeightDefault,
        lastY = 0,
        animationID = 0;

    export function showCard() {
        cardHeight = cardHeightDefault;
        showing = true;
    }
    // export function extendCard() {
    //     extended = true;
    // }
    export function setPin(happening) {
        currentPin = happening;
    }

    export function fmt(happening) {
        if (!happening.t0_fmt) {
            happening.t0_fmt = readableTime(happening.t0);
        }
        if (happening.title.length > 50) {
            happening.title = happening.title.slice(0, 55) + "...";
        }
        if (happening.description.length > 400) {
            happening.description = happening.description.slice(0, 400) + "...";
        }
        if (!happening.t1_fmt) {
            happening.t1_fmt = readableTime(happening.t1);
        }
        if (!happening.date_fmt) {
            // let tmp = new Date(happening.date)
            happening.date_fmt = formatter.format(new Date(happening.date));
        }
    }

    // function for converting time
    function readableTime(tt) {
        let date = new Date(tt);
        let hour = ("0" + date.getHours()).slice(-2);
        let minutes = ("0" + date.getMinutes()).slice(-2);
        let time = hour + ":" + minutes;
        return time;
    }

    export function closeCard() {
        // if (extended) {
        //     deextendCard();
        // }
        // showing = extended = false;
        showing = false;
        cardHeight = cardHeightDefault;
        window.location.hash = "";
    }

    // function getCardPadBot() {
    //     return parseInt(
    //         window.getComputedStyle(card).getPropertyValue("padding-bottom")
    //     );
    // }
    // function getButtonBottomOffset() {
    //     return parseInt(
    //         window.getComputedStyle(button).getPropertyValue("bottom")
    //     );
    // }
    // function deextendCard() {
    //     extended = false;
    //     card.style.height = "35%";
    //     cardPadBot = getCardPadBot();
    //     cardPadBot -= getButtonBottomOffset();
    //     cardPadBot -= buttonHeight;
    //     cardPadBot += manualPad;
    //     card.style.paddingBottom = cardPadBot + "px";
    // }

    // Event callbacks
    function touchStart(index) {
        return function (event) {
            lastY = getY(event);
            isDragging = true;
            animationID = requestAnimationFrame(animation);
        };
    }

    function touchEnd() {
        isDragging = false;
        cancelAnimationFrame(animationID);
    }

    function touchMove(event) {
        if (isDragging) {
            const currentY = getY(event);
            const newHeight = cardHeight - (currentY - lastY);
            cardHeight = newHeight > cardHeightMax ? cardHeightMax : newHeight;
            cardHeight =
                cardHeight < cardHeightMin ? cardHeightMin : cardHeight;
            lastY = currentY;
        }
    }

    // Helper functions
    function getY(event) {
        return event.type.includes("mouse")
            ? event.pageY
            : event.touches[0].pageY;
    }

    function animation() {
        isDragging && requestAnimationFrame(animation);
    }

    async function shareCard() {
        const url = `${window.location.href}`;
        try {
            await navigator.share({
                title: "Check this out!",
                text:
                    "Check out " +
                    currentPin.title +
                    " at " +
                    currentPin.venue +
                    "!",
                url: url,
            });
        } catch (e) {}
    }
</script>

<transition>
    {#if showing}
        <div
            class="card sketchy"
            bind:this={card}
            style="height: {cardHeight}px"
            on:touchstart={touchStart(this)}
            on:touchend={touchEnd}
            on:touchmove={touchMove}
            on:mousedown={touchStart(this)}
            on:mouseup={touchEnd}
            on:mouseleave={touchEnd}
            on:mousemove={touchEnd}
            in:fly={{ y: 200, duration: 500 }}
            out:fly={{ y: 200, duration: 500 }}
        >
            <h1>{currentPin.title}</h1>
            <h2>{currentPin.venue}</h2>
            <h2>
                {currentPin.date_fmt}, {currentPin.t0_fmt} - {currentPin.t1_fmt}
            </h2>
            <h3>{currentPin.address}</h3>
            <p>{currentPin.description}</p>
            <a
                class="cardButton sketchyButton"
                href={currentPin.url}
                bind:this={button}
                bind:clientHeight={buttonHeight}
            >
                Go to Event Page
            </a>

            <div style="display: flex; justify-content: flex-end">
                <div
                    on:click={shareCard}
                    on:keyup={shareCard}
                    class="cardButton sketchyButton"
                    style="width: 22vw;"
                >
                    Share
                </div>
            </div>
        </div>
    {/if}
</transition>

<style>
    .card h1,
    .card h2,
    .card h3 {
        margin-top: 0.15em;
        margin-bottom: 0.65em;
    }
    .card p {
        margin-top: 0.7em;
        margin-bottom: 0.8em;
    }

    .card {
        position: absolute;
        left: 0.5vw;
        right: 0.5vw;
        bottom: 0;
        padding: 16px;
        background: linear-gradient(
            /* Calendar mode */ -20deg,
            #e9defa 0%,
            #fbfcdb 100% /* Day mode */
                /* 155deg,
            rgba(207,167,255,0.85),
            rgba(222,145,223,0.85) */
                /* Night mode: */
                /* rgba(249, 165, 69, 0.85),
            rgba(93, 47, 225, 0.85) */
        );
        /* box-shadow: 0px 0px 6vw 6vw rgba(0, 0, 0, 0.15); */
        z-index: 1;
        font-family: "PermanentMarker-Regular";
        font-size: 0.9em;
        line-height: 1.5;
        color: rgb(0, 0, 0);
        border: 1vh solid rgb(0, 0, 0);
        border-bottom: 0;
        overflow: hidden;
        border-top-left-radius: 63px 33px;
        border-top-right-radius: 24px 46px;
        /* border-bottom-right-radius: 225px 15px;
        border-bottom-left-radius: 15px 255px; */
    }

    .cardButton {
        background-color: rgb(0, 0, 0); /* or any other color you prefer */
        /* offset-x | offset-y | blur-radius | spread-radius | color */
        /* box-shadow: 0px 0px 65px 65px rgba(93, 47, 225, 0.85); */
        transform: translateZ(2px);
        color: white; /* or any other color you prefer */
        margin: 0px;
        border: 0px;
        padding: 10px;
        text-decoration: none;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "PermanentMarker-Regular";
        border-radius: 6px;
        position: fixed;
        bottom: 3vh;
        width: 52vw;
        border-top-left-radius: 59px 44px;
        border-top-right-radius: 47px 40px;
        border-bottom-right-radius: 46px 57px;
        border-bottom-left-radius: 46px 36px;
    }

    .sketchy {
        clip-path: polygon(
            0% 100%,
            1.57px 99%,
            1.56px 98%,
            1.54px 97%,
            1.52px 96%,
            1.49px 95%,
            1.47px 94%,
            1.44px 93%,
            1.41px 92%,
            1.38px 91%,
            1.36px 90%,
            1.34px 89%,
            1.33px 88%,
            1.32px 87%,
            1.33px 86%,
            1.34px 85%,
            1.36px 84%,
            1.39px 83%,
            1.44px 82%,
            1.5px 81%,
            1.58px 80%,
            1.67px 79%,
            1.76px 78%,
            1.82px 77%,
            1.84px 76%,
            1.8px 75%,
            1.69px 74%,
            1.52px 73%,
            1.31px 72%,
            1.1px 71%,
            0.9px 70%,
            0.75px 69%,
            0.62px 68%,
            0.54px 67%,
            0.48px 66%,
            0.45px 65%,
            0.44px 64%,
            0.46px 63%,
            0.49px 62%,
            0.53px 61%,
            0.59px 60%,
            0.65px 59%,
            0.71px 58%,
            0.77px 57%,
            0.83px 56%,
            0.89px 55%,
            0.93px 54%,
            0.95px 53%,
            0.96px 52%,
            0.94px 51%,
            0.9px 50%,
            0.84px 49%,
            0.75px 48%,
            0.65px 47%,
            0.53px 46%,
            0.42px 45%,
            0.3px 44%,
            0.2px 43%,
            0.11px 42%,
            0.04px 41%,
            0px 40%,
            0px 39%,
            0.03px 38%,
            0.08px 37%,
            0.16px 36%,
            0.26px 35%,
            0.38px 34%,
            0.52px 33%,
            0.67px 32%,
            0.83px 31%,
            0.99px 30%,
            1.16px 29%,
            1.32px 28%,
            1.49px 27%,
            1.64px 26%,
            1.79px 25%,
            1.92px 24%,
            2.04px 23%,
            2.13px 22%,
            2.21px 21%,
            2.25px 20%,
            2.27px 19%,
            2.27px 18%,
            2.24px 17%,
            2.19px 16%,
            2.13px 15%,
            2.05px 14%,
            1.96px 13%,
            1.87px 12%,
            1.78px 11%,
            1.69px 10%,
            1.6px 9%,
            1.52px 8%,
            1.45px 7%,
            1.39px 6%,
            1.35px 5%,
            1.34px 4%,
            1.33px 3%,
            1.34px 2%,
            1.35px 1%,
            1.35px 0%,
            0% 1.35px,
            1% 1.35px,
            2% 1.34px,
            3% 1.33px,
            4% 1.34px,
            5% 1.35px,
            6% 1.39px,
            7% 1.45px,
            8% 1.52px,
            9% 1.6px,
            10% 1.69px,
            11% 1.78px,
            12% 1.87px,
            13% 1.96px,
            14% 2.05px,
            15% 2.13px,
            16% 2.19px,
            17% 2.24px,
            18% 2.27px,
            19% 2.27px,
            20% 2.25px,
            21% 2.21px,
            22% 2.13px,
            23% 2.04px,
            24% 1.92px,
            25% 1.79px,
            26% 1.64px,
            27% 1.49px,
            28% 1.32px,
            29% 1.16px,
            30% 0.99px,
            31% 0.83px,
            32% 0.67px,
            33% 0.52px,
            34% 0.38px,
            35% 0.26px,
            36% 0.16px,
            37% 0.08px,
            38% 0.03px,
            39% 0px,
            40% 0px,
            41% 0.04px,
            42% 0.11px,
            43% 0.2px,
            44% 0.3px,
            45% 0.42px,
            46% 0.53px,
            47% 0.65px,
            48% 0.75px,
            49% 0.84px,
            50% 0.9px,
            51% 0.94px,
            52% 0.96px,
            53% 0.95px,
            54% 0.93px,
            55% 0.89px,
            56% 0.83px,
            57% 0.77px,
            58% 0.71px,
            59% 0.65px,
            60% 0.59px,
            61% 0.53px,
            62% 0.49px,
            63% 0.46px,
            64% 0.44px,
            65% 0.45px,
            66% 0.48px,
            67% 0.54px,
            68% 0.62px,
            69% 0.75px,
            70% 0.9px,
            71% 1.1px,
            72% 1.31px,
            73% 1.52px,
            74% 1.69px,
            75% 1.8px,
            76% 1.84px,
            77% 1.82px,
            78% 1.76px,
            79% 1.67px,
            80% 1.58px,
            81% 1.5px,
            82% 1.44px,
            83% 1.39px,
            84% 1.36px,
            85% 1.34px,
            86% 1.33px,
            87% 1.32px,
            88% 1.33px,
            89% 1.34px,
            90% 1.36px,
            91% 1.38px,
            92% 1.41px,
            93% 1.44px,
            94% 1.47px,
            95% 1.49px,
            96% 1.52px,
            97% 1.54px,
            98% 1.56px,
            99% 1.57px,
            calc(100% - 1.35px) 0%,
            calc(100% - 1.35px) 1%,
            calc(100% - 1.34px) 2%,
            calc(100% - 1.33px) 3%,
            calc(100% - 1.34px) 4%,
            calc(100% - 1.35px) 5%,
            calc(100% - 1.39px) 6%,
            calc(100% - 1.45px) 7%,
            calc(100% - 1.52px) 8%,
            calc(100% - 1.6px) 9%,
            calc(100% - 1.69px) 10%,
            calc(100% - 1.78px) 11%,
            calc(100% - 1.87px) 12%,
            calc(100% - 1.96px) 13%,
            calc(100% - 2.05px) 14%,
            calc(100% - 2.13px) 15%,
            calc(100% - 2.19px) 16%,
            calc(100% - 2.24px) 17%,
            calc(100% - 2.27px) 18%,
            calc(100% - 2.27px) 19%,
            calc(100% - 2.25px) 20%,
            calc(100% - 2.21px) 21%,
            calc(100% - 2.13px) 22%,
            calc(100% - 2.04px) 23%,
            calc(100% - 1.92px) 24%,
            calc(100% - 1.79px) 25%,
            calc(100% - 1.64px) 26%,
            calc(100% - 1.49px) 27%,
            calc(100% - 1.32px) 28%,
            calc(100% - 1.16px) 29%,
            calc(100% - 0.99px) 30%,
            calc(100% - 0.83px) 31%,
            calc(100% - 0.67px) 32%,
            calc(100% - 0.52px) 33%,
            calc(100% - 0.38px) 34%,
            calc(100% - 0.26px) 35%,
            calc(100% - 0.16px) 36%,
            calc(100% - 0.08px) 37%,
            calc(100% - 0.03px) 38%,
            calc(100% - 0px) 39%,
            calc(100% - 0px) 40%,
            calc(100% - 0.04px) 41%,
            calc(100% - 0.11px) 42%,
            calc(100% - 0.2px) 43%,
            calc(100% - 0.3px) 44%,
            calc(100% - 0.42px) 45%,
            calc(100% - 0.53px) 46%,
            calc(100% - 0.65px) 47%,
            calc(100% - 0.75px) 48%,
            calc(100% - 0.84px) 49%,
            calc(100% - 0.9px) 50%,
            calc(100% - 0.94px) 51%,
            calc(100% - 0.96px) 52%,
            calc(100% - 0.95px) 53%,
            calc(100% - 0.93px) 54%,
            calc(100% - 0.89px) 55%,
            calc(100% - 0.83px) 56%,
            calc(100% - 0.77px) 57%,
            calc(100% - 0.71px) 58%,
            calc(100% - 0.65px) 59%,
            calc(100% - 0.59px) 60%,
            calc(100% - 0.53px) 61%,
            calc(100% - 0.49px) 62%,
            calc(100% - 0.46px) 63%,
            calc(100% - 0.44px) 64%,
            calc(100% - 0.45px) 65%,
            calc(100% - 0.48px) 66%,
            calc(100% - 0.54px) 67%,
            calc(100% - 0.62px) 68%,
            calc(100% - 0.75px) 69%,
            calc(100% - 0.9px) 70%,
            calc(100% - 1.1px) 71%,
            calc(100% - 1.31px) 72%,
            calc(100% - 1.52px) 73%,
            calc(100% - 1.69px) 74%,
            calc(100% - 1.8px) 75%,
            calc(100% - 1.84px) 76%,
            calc(100% - 1.82px) 77%,
            calc(100% - 1.76px) 78%,
            calc(100% - 1.67px) 79%,
            calc(100% - 1.58px) 80%,
            calc(100% - 1.5px) 81%,
            calc(100% - 1.44px) 82%,
            calc(100% - 1.39px) 83%,
            calc(100% - 1.36px) 84%,
            calc(100% - 1.34px) 85%,
            calc(100% - 1.33px) 86%,
            calc(100% - 1.32px) 87%,
            calc(100% - 1.33px) 88%,
            calc(100% - 1.34px) 89%,
            calc(100% - 1.36px) 90%,
            calc(100% - 1.38px) 91%,
            calc(100% - 1.41px) 92%,
            calc(100% - 1.44px) 93%,
            calc(100% - 1.47px) 94%,
            calc(100% - 1.49px) 95%,
            calc(100% - 1.52px) 96%,
            calc(100% - 1.54px) 97%,
            calc(100% - 1.56px) 98%,
            calc(100% - 1.57px) 99%,
            100% 100%
        );
    }

    .sketchyButton {
        clip-path: polygon(
            0% 100%,
            1.57px 99%,
            1.56px 98%,
            1.54px 97%,
            1.52px 96%,
            1.49px 95%,
            1.47px 94%,
            1.44px 93%,
            1.41px 92%,
            1.38px 91%,
            1.36px 90%,
            1.34px 89%,
            1.33px 88%,
            1.32px 87%,
            1.33px 86%,
            1.34px 85%,
            1.36px 84%,
            1.39px 83%,
            1.44px 82%,
            1.5px 81%,
            1.58px 80%,
            1.67px 79%,
            1.76px 78%,
            1.82px 77%,
            1.84px 76%,
            1.8px 75%,
            1.69px 74%,
            1.52px 73%,
            1.31px 72%,
            1.1px 71%,
            0.9px 70%,
            0.75px 69%,
            0.62px 68%,
            0.54px 67%,
            0.48px 66%,
            0.45px 65%,
            0.44px 64%,
            0.46px 63%,
            0.49px 62%,
            0.53px 61%,
            0.59px 60%,
            0.65px 59%,
            0.71px 58%,
            0.77px 57%,
            0.83px 56%,
            0.89px 55%,
            0.93px 54%,
            0.95px 53%,
            0.96px 52%,
            0.94px 51%,
            0.9px 50%,
            0.84px 49%,
            0.75px 48%,
            0.65px 47%,
            0.53px 46%,
            0.42px 45%,
            0.3px 44%,
            0.2px 43%,
            0.11px 42%,
            0.04px 41%,
            0px 40%,
            0px 39%,
            0.03px 38%,
            0.08px 37%,
            0.16px 36%,
            0.26px 35%,
            0.38px 34%,
            0.52px 33%,
            0.67px 32%,
            0.83px 31%,
            0.99px 30%,
            1.16px 29%,
            1.32px 28%,
            1.49px 27%,
            1.64px 26%,
            1.79px 25%,
            1.92px 24%,
            2.04px 23%,
            2.13px 22%,
            2.21px 21%,
            2.25px 20%,
            2.27px 19%,
            2.27px 18%,
            2.24px 17%,
            2.19px 16%,
            2.13px 15%,
            2.05px 14%,
            1.96px 13%,
            1.87px 12%,
            1.78px 11%,
            1.69px 10%,
            1.6px 9%,
            1.52px 8%,
            1.45px 7%,
            1.39px 6%,
            1.35px 5%,
            1.34px 4%,
            1.33px 3%,
            1.34px 2%,
            1.35px 1%,
            1.35px 0%,
            0% 1.35px,
            1% 1.35px,
            2% 1.34px,
            3% 1.33px,
            4% 1.34px,
            5% 1.35px,
            6% 1.39px,
            7% 1.45px,
            8% 1.52px,
            9% 1.6px,
            10% 1.69px,
            11% 1.78px,
            12% 1.87px,
            13% 1.96px,
            14% 2.05px,
            15% 2.13px,
            16% 2.19px,
            17% 2.24px,
            18% 2.27px,
            19% 2.27px,
            20% 2.25px,
            21% 2.21px,
            22% 2.13px,
            23% 2.04px,
            24% 1.92px,
            25% 1.79px,
            26% 1.64px,
            27% 1.49px,
            28% 1.32px,
            29% 1.16px,
            30% 0.99px,
            31% 0.83px,
            32% 0.67px,
            33% 0.52px,
            34% 0.38px,
            35% 0.26px,
            36% 0.16px,
            37% 0.08px,
            38% 0.03px,
            39% 0px,
            40% 0px,
            41% 0.04px,
            42% 0.11px,
            43% 0.2px,
            44% 0.3px,
            45% 0.42px,
            46% 0.53px,
            47% 0.65px,
            48% 0.75px,
            49% 0.84px,
            50% 0.9px,
            51% 0.94px,
            52% 0.96px,
            53% 0.95px,
            54% 0.93px,
            55% 0.89px,
            56% 0.83px,
            57% 0.77px,
            58% 0.71px,
            59% 0.65px,
            60% 0.59px,
            61% 0.53px,
            62% 0.49px,
            63% 0.46px,
            64% 0.44px,
            65% 0.45px,
            66% 0.48px,
            67% 0.54px,
            68% 0.62px,
            69% 0.75px,
            70% 0.9px,
            71% 1.1px,
            72% 1.31px,
            73% 1.52px,
            74% 1.69px,
            75% 1.8px,
            76% 1.84px,
            77% 1.82px,
            78% 1.76px,
            79% 1.67px,
            80% 1.58px,
            81% 1.5px,
            82% 1.44px,
            83% 1.39px,
            84% 1.36px,
            85% 1.34px,
            86% 1.33px,
            87% 1.32px,
            88% 1.33px,
            89% 1.34px,
            90% 1.36px,
            91% 1.38px,
            92% 1.41px,
            93% 1.44px,
            94% 1.47px,
            95% 1.49px,
            96% 1.52px,
            97% 1.54px,
            98% 1.56px,
            99% 1.57px,
            calc(100% - 1.35px) 0%,
            calc(100% - 1.35px) 1%,
            calc(100% - 1.34px) 2%,
            calc(100% - 1.33px) 3%,
            calc(100% - 1.34px) 4%,
            calc(100% - 1.35px) 5%,
            calc(100% - 1.39px) 6%,
            calc(100% - 1.45px) 7%,
            calc(100% - 1.52px) 8%,
            calc(100% - 1.6px) 9%,
            calc(100% - 1.69px) 10%,
            calc(100% - 1.78px) 11%,
            calc(100% - 1.87px) 12%,
            calc(100% - 1.96px) 13%,
            calc(100% - 2.05px) 14%,
            calc(100% - 2.13px) 15%,
            calc(100% - 2.19px) 16%,
            calc(100% - 2.24px) 17%,
            calc(100% - 2.27px) 18%,
            calc(100% - 2.27px) 19%,
            calc(100% - 2.25px) 20%,
            calc(100% - 2.21px) 21%,
            calc(100% - 2.13px) 22%,
            calc(100% - 2.04px) 23%,
            calc(100% - 1.92px) 24%,
            calc(100% - 1.79px) 25%,
            calc(100% - 1.64px) 26%,
            calc(100% - 1.49px) 27%,
            calc(100% - 1.32px) 28%,
            calc(100% - 1.16px) 29%,
            calc(100% - 0.99px) 30%,
            calc(100% - 0.83px) 31%,
            calc(100% - 0.67px) 32%,
            calc(100% - 0.52px) 33%,
            calc(100% - 0.38px) 34%,
            calc(100% - 0.26px) 35%,
            calc(100% - 0.16px) 36%,
            calc(100% - 0.08px) 37%,
            calc(100% - 0.03px) 38%,
            calc(100% - 0px) 39%,
            calc(100% - 0px) 40%,
            calc(100% - 0.04px) 41%,
            calc(100% - 0.11px) 42%,
            calc(100% - 0.2px) 43%,
            calc(100% - 0.3px) 44%,
            calc(100% - 0.42px) 45%,
            calc(100% - 0.53px) 46%,
            calc(100% - 0.65px) 47%,
            calc(100% - 0.75px) 48%,
            calc(100% - 0.84px) 49%,
            calc(100% - 0.9px) 50%,
            calc(100% - 0.94px) 51%,
            calc(100% - 0.96px) 52%,
            calc(100% - 0.95px) 53%,
            calc(100% - 0.93px) 54%,
            calc(100% - 0.89px) 55%,
            calc(100% - 0.83px) 56%,
            calc(100% - 0.77px) 57%,
            calc(100% - 0.71px) 58%,
            calc(100% - 0.65px) 59%,
            calc(100% - 0.59px) 60%,
            calc(100% - 0.53px) 61%,
            calc(100% - 0.49px) 62%,
            calc(100% - 0.46px) 63%,
            calc(100% - 0.44px) 64%,
            calc(100% - 0.45px) 65%,
            calc(100% - 0.48px) 66%,
            calc(100% - 0.54px) 67%,
            calc(100% - 0.62px) 68%,
            calc(100% - 0.75px) 69%,
            calc(100% - 0.9px) 70%,
            calc(100% - 1.1px) 71%,
            calc(100% - 1.31px) 72%,
            calc(100% - 1.52px) 73%,
            calc(100% - 1.69px) 74%,
            calc(100% - 1.8px) 75%,
            calc(100% - 1.84px) 76%,
            calc(100% - 1.82px) 77%,
            calc(100% - 1.76px) 78%,
            calc(100% - 1.67px) 79%,
            calc(100% - 1.58px) 80%,
            calc(100% - 1.5px) 81%,
            calc(100% - 1.44px) 82%,
            calc(100% - 1.39px) 83%,
            calc(100% - 1.36px) 84%,
            calc(100% - 1.34px) 85%,
            calc(100% - 1.33px) 86%,
            calc(100% - 1.32px) 87%,
            calc(100% - 1.33px) 88%,
            calc(100% - 1.34px) 89%,
            calc(100% - 1.36px) 90%,
            calc(100% - 1.38px) 91%,
            calc(100% - 1.41px) 92%,
            calc(100% - 1.44px) 93%,
            calc(100% - 1.47px) 94%,
            calc(100% - 1.49px) 95%,
            calc(100% - 1.52px) 96%,
            calc(100% - 1.54px) 97%,
            calc(100% - 1.56px) 98%,
            calc(100% - 1.57px) 99%,
            99% calc(100% - 1.57px),
            98% calc(100% - 1.56px),
            97% calc(100% - 1.54px),
            96% calc(100% - 1.52px),
            95% calc(100% - 1.49px),
            94% calc(100% - 1.47px),
            93% calc(100% - 1.44px),
            92% calc(100% - 1.41px),
            91% calc(100% - 1.38px),
            90% calc(100% - 1.36px),
            89% calc(100% - 1.34px),
            88% calc(100% - 1.33px),
            87% calc(100% - 1.32px),
            86% calc(100% - 1.33px),
            85% calc(100% - 1.34px),
            84% calc(100% - 1.36px),
            83% calc(100% - 1.39px),
            82% calc(100% - 1.44px),
            81% calc(100% - 1.5px),
            80% calc(100% - 1.58px),
            79% calc(100% - 1.67px),
            78% calc(100% - 1.76px),
            77% calc(100% - 1.82px),
            76% calc(100% - 1.84px),
            75% calc(100% - 1.8px),
            74% calc(100% - 1.69px),
            73% calc(100% - 1.52px),
            72% calc(100% - 1.31px),
            71% calc(100% - 1.1px),
            70% calc(100% - 0.9px),
            69% calc(100% - 0.75px),
            68% calc(100% - 0.62px),
            67% calc(100% - 0.54px),
            66% calc(100% - 0.48px),
            65% calc(100% - 0.45px),
            64% calc(100% - 0.44px),
            63% calc(100% - 0.46px),
            62% calc(100% - 0.49px),
            61% calc(100% - 0.53px),
            60% calc(100% - 0.59px),
            59% calc(100% - 0.65px),
            58% calc(100% - 0.71px),
            57% calc(100% - 0.77px),
            56% calc(100% - 0.83px),
            55% calc(100% - 0.89px),
            54% calc(100% - 0.93px),
            53% calc(100% - 0.95px),
            52% calc(100% - 0.96px),
            51% calc(100% - 0.94px),
            50% calc(100% - 0.9px),
            49% calc(100% - 0.84px),
            48% calc(100% - 0.75px),
            47% calc(100% - 0.65px),
            46% calc(100% - 0.53px),
            45% calc(100% - 0.42px),
            44% calc(100% - 0.3px),
            43% calc(100% - 0.2px),
            42% calc(100% - 0.11px),
            41% calc(100% - 0.04px),
            40% calc(100% - 0px),
            39% calc(100% - 0px),
            38% calc(100% - 0.03px),
            37% calc(100% - 0.08px),
            36% calc(100% - 0.16px),
            35% calc(100% - 0.26px),
            34% calc(100% - 0.38px),
            33% calc(100% - 0.52px),
            32% calc(100% - 0.67px),
            31% calc(100% - 0.83px),
            30% calc(100% - 0.99px),
            29% calc(100% - 1.16px),
            28% calc(100% - 1.32px),
            27% calc(100% - 1.49px),
            26% calc(100% - 1.64px),
            25% calc(100% - 1.79px),
            24% calc(100% - 1.92px),
            23% calc(100% - 2.04px),
            22% calc(100% - 2.13px),
            21% calc(100% - 2.21px),
            20% calc(100% - 2.25px),
            19% calc(100% - 2.27px),
            18% calc(100% - 2.27px),
            17% calc(100% - 2.24px),
            16% calc(100% - 2.19px),
            15% calc(100% - 2.13px),
            14% calc(100% - 2.05px),
            13% calc(100% - 1.96px),
            12% calc(100% - 1.87px),
            11% calc(100% - 1.78px),
            10% calc(100% - 1.69px),
            9% calc(100% - 1.6px),
            8% calc(100% - 1.52px),
            7% calc(100% - 1.45px),
            6% calc(100% - 1.39px),
            5% calc(100% - 1.35px),
            4% calc(100% - 1.34px),
            3% calc(100% - 1.33px),
            2% calc(100% - 1.34px),
            1% calc(100% - 1.35px),
            0% calc(100% - 1.35px),
            0% 100%
        );
    }
</style>
