<script>
    import MainMap from "./components/MainMap.svelte";
    import SaveBanner from "./components/SaveBanner.svelte";
    import DateSelector from "./components/DateSelector.svelte";
    import HappenigAdder from "./components/HappeningAdder.svelte";

    // Get today's date and the next 7 days at midnight
    const date = new Date();
    const tdy = Math.floor(date.setHours(0, 0, 0, 0));

    let selectedDates = [tdy];
    for (let i = 1; i <= 7; i++) {
        date.setDate(date.getDate() + 1);
        selectedDates.push(Math.floor(date.setHours(0, 0, 0, 0)));
    }
</script>

<MainMap bind:selectedDates />
<DateSelector bind:selectedDates />
<HappenigAdder />

<!-- <SaveBanner /> -->
<style>
    :global(html, head) {
        /* https://stackoverflow.com/questions/69261011/disable-pull-to-refresh-in-ios-15-safari/69589919#69589919
        iOS 15 bug? 
        has to be both html and head I think, not sure */
        overscroll-behavior: none;
    }

    /* Define styles for the component */
    :global(body) {
        height: 100vw;
        width: 100vw;
        padding: 0;
    }
</style>
