import App from './App.svelte';

const app = new App({
  target: document.body
});

export default app;

if ('serviceWorker' in navigator) {
  window.addEventListener('load', async () => {
    try {
      const registration = await navigator.serviceWorker.register('/sw.js', { scope: '/' });
      console.log('Service worker registered: ', registration);
    } catch (registrationError) {
      console.log('Service worker registration failed: ', registrationError);
    }
  });
}

function hasTouch() {
  return (
    "ontouchstart" in document.documentElement ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
}

if (hasTouch()) {
  // remove all the :hover stylesheets
  try {
    // prevent exception on browsers not supporting DOM styleSheets properly
    for (var si in document.styleSheets) {
      var styleSheet = document.styleSheets[si];
      if (!styleSheet.cssRules) continue;

      for (var ri = styleSheet.cssRules.length - 1; ri >= 0; ri--) {
        if (!styleSheet.cssRules[ri].selectorText) continue;

        if (styleSheet.cssRules[ri].selectorText.match(":hover")) {
          styleSheet.deleteRule(ri);
        }
      }
    }
  } catch (ex) { }
}