<script>
    import { onMount } from "svelte";
    import { fly, fade } from "svelte/transition";

    const MAX_TITLE_LENGTH = 40;
    const TIME_STEP = 900; // 15 minutes
    const TITLE = "House Warming!";
    const VENUE = "OG18";
    const ADDRESS = "Olofsgatan 18";
    const CITY = "Stockholm";
    const DESCRIPTION = "such amaze! much wow!";
    const DEFAULT_URL = "https://getquepasa.app";
    let isFormVisible = false;

    let title = TITLE;
    let venue = VENUE;
    let address = ADDRESS;
    let city = CITY;
    let t1;
    let t0 = new Date();
    const TZ_OFFSET = t0.getTimezoneOffset() * 60000; // convert to milliseconds
    let description = DESCRIPTION;
    let url = DEFAULT_URL;

    // Convert to ISO string (requirement for HTML input type="datetime-local") and floor to previous 15 minutes
    $: {
        t0 = Date.parse(t0);
        t0 = t0 - TZ_OFFSET;
        t0 = t0 - (t0 % (TIME_STEP * 1000));
        t0 = new Date(t0).toISOString().slice(0, 16);
        t1 = t0;
    }
    $: {
        t1 = Date.parse(t1);
        t1 = t1 - TZ_OFFSET;
        t1 = t1 - (t1 % (TIME_STEP * 1000));
        t1 = new Date(t1).toISOString().slice(0, 16);
    }

    function showForm() {
        isFormVisible = true;
    }

    function hideForm() {
        isFormVisible = false;
    }

    function clearForm() {
        title = TITLE;
        t0 = new Date();
        venue = VENUE;
        address = ADDRESS;
        city = CITY;
        description = DESCRIPTION;
        url = DEFAULT_URL;
    }

    async function handleSubmit() {
        if (t1 < t0) {
            alert(`End time must be after start time`);
            return;
        }

        await fetch(`/api/createHappening`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ title, t0, t1, venue, address, city, description, url }),
        });

        clearForm();
        hideForm();
    }

    function handleClickOutside(event) {
        if (
            !event.target.closest(".form-container") &&
            !event.target.closest(".adderButton")
        ) {
            isFormVisible = false;
        }
    }

    onMount(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    });
</script>

<transition>
    {#if isFormVisible}
        <div
            class="overlay"
            on:click={hideForm}
            on:keyup={hideForm}
            in:fade={{ duration: 500 }}
            out:fade={{ duration: 500 }}
        />
        <div
            class="form-container"
            in:fly={{ y: -200, duration: 500 }}
            out:fly={{ y: -200, duration: 500 }}
        >
            <form on:submit|preventDefault={handleSubmit}>
                <h2>Create Happening</h2>
                <div class="form-field">
                    <label for="title" class="form-label">Title*</label>
                    <input
                        type="text"
                        id="title"
                        bind:value={title}
                        class="form-input"
                        required
                        maxlength={MAX_TITLE_LENGTH}
                    />
                </div>
                <div class="form-field">
                    <label for="datetime" class="form-label">Start time*</label>
                    <input
                        type="datetime-local"
                        id="datetime"
                        bind:value={t0}
                        class="form-input"
                        step={TIME_STEP}
                        required
                    />
                </div>
                <div class="form-field">
                    <label for="datetime" class="form-label">End time*</label>
                    <input
                        type="datetime-local"
                        id="datetime"
                        bind:value={t1}
                        class="form-input"
                        step={TIME_STEP}
                        required
                    />
                </div>
                <div class="form-field">
                    <label for="venue" class="form-label">Venue*</label>
                    <input
                        type="text"
                        id="venue"
                        bind:value={venue}
                        class="form-input"
                        required
                    />
                </div>
                <div class="form-field">
                    <label for="address" class="form-label">Address*</label>
                    <input
                        type="text"
                        id="address"
                        bind:value={address}
                        class="form-input"
                        required
                    />
                </div>
                <div class="form-field">
                    <label for="city" class="form-label">City*</label>
                    <input
                        type="text"
                        id="city"
                        bind:value={city}
                        class="form-input"
                        required
                    />
                </div>
                <div class="form-field">
                    <label for="description" class="form-label"
                        >Description</label
                    >
                    <textarea
                        id="description"
                        bind:value={description}
                        class="form-input"
                    />
                </div>
                <div class="form-field">
                    <label for="url" class="form-label">URL</label>
                    <input
                        type="url"
                        id="url"
                        bind:value={url}
                        class="form-input"
                    />
                </div>
                <div class="form-buttons">
                    <button
                        type="button"
                        class="cancel-button"
                        on:click={hideForm}>Cancel</button
                    >
                    <button
                        type="button"
                        class="clear-button"
                        on:click={clearForm}>Clear</button
                    >
                    <button type="submit" class="form-submit">Submit</button>
                </div>
            </form>
        </div>
    {/if}
</transition>

<div
    class="adderButton"
    in:fade={{ duration: 150 }}
    out:fade={{ duration: 150 }}
    on:click={showForm}
    on:keyup={showForm}
>
    +
</div>

<style>
    .adderButton {
        position: fixed;
        bottom: 18vh;
        right: 5vw;
        background-color: hsl(264, 74%, 83%);
        /* background-color: rgb(0, 0, 0); */
        color: white;
        margin: 0px;
        border: 0px;
        padding: 10px;
        padding-bottom: 4px;
        text-decoration: none;
        font-size: 20px;
        align-items: center;
        justify-content: center;
        font-family: "PermanentMarker-Regular";
        border-radius: 6px;
        border-top-left-radius: 59px 44px;
        border-top-right-radius: 47px 40px;
        border-bottom-right-radius: 46px 57px;
        border-bottom-left-radius: 46px 36px;
    }

    /* Styles for the overlay */
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 100;
    }

    /* Styles for the form container */
    .form-container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -55%);
        width: 80vw;
        height: 80vh;
        padding: 1rem;
        /* background-color: #fff; */
        background-image: linear-gradient(
            -20deg,
            rgb(233, 222, 250),
            rgb(251, 252, 219)
        );
        border-radius: 0.5rem;
        z-index: 101;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        font-family: "PermanentMarker-Regular";
    }

    /* Styles for the form elements */
    .form-field {
        margin-bottom: 0.2rem;
    }

    .form-label {
        display: block;
        font-weight: bold;
        margin-bottom: 0.2rem;
    }

    .form-input {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 0.25rem;
    }

    .form-input:required:invalid {
        border-color: #f44336;
    }

    .form-input:required:valid {
        border-color: #4caf50;
    }

    .form-input:invalid:focus {
        outline-color: #f44336;
    }

    .form-input:valid:focus {
        outline-color: #4caf50;
    }

    .form-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
    }

    .cancel-button {
        display: inline-block;
        padding: 0.5rem 1rem;
        background-color: #ff6b6b;
        color: #fff;
        border: none;
        border-radius: 0.25rem;
        cursor: pointer;
    }

    .cancel-button:hover {
        background-color: #e74c3c;
    }

    .clear-button {
        display: inline-block;
        padding: 0.5rem 1rem;
        background-color: #ffb36b;
        color: #fff;
        border: none;
        border-radius: 0.25rem;
        cursor: pointer;
    }

    .clear-button:hover {
        background-color: #e7973c;
    }

    .form-submit {
        display: inline-block;
        padding: 0.5rem;
        background-color: #4caf50;
        color: #fff;
        border: none;
        border-radius: 0.25rem;
        cursor: pointer;
    }

    .form-submit:hover {
        background-color: #388e3c;
    }
</style>
