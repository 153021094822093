<script>
    import { onMount } from "svelte";
    import { fly, fade } from "svelte/transition";
    import Datepicker from "praecox-datepicker";

    let isOpen = false;
    export let selectedDates;

    function handleClickOutside(event) {
        if (
            !event.target.closest(".calendarStyle") &&
            !event.target.closest(".dateButton")
        ) {
            isOpen = false;
        }
    }

    onMount(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    });
</script>

<transition>
    {#if isOpen}
        <div
            class="calendarStyle"
            in:fly={{ y: -200, duration: 500 }}
            out:fly={{ y: -200, duration: 500 }}
        >
            <Datepicker
                theme="light"
                pickerRule="free"
                finishBtn={false}
                bind:selected={selectedDates}
            />
        </div>
    {/if}
</transition>

<div
    class="dateButton"
    in:fade={{ duration: 150 }}
    out:fade={{ duration: 150 }}
    on:click={() => (isOpen = !isOpen)}
    on:keyup={() => (isOpen = !isOpen)}
>
    <img class="icon" src="img/svg/calendar.svg" alt="Calendar Icon" />
</div>

<style>
    .icon {
        width: 30px;
        height: 30px;
    }

    .dateButton {
        position: fixed;
        bottom: 10vh;
        right: 5vw;
        background-color: hsl(264, 74%, 83%);
        /* background-color: rgb(0, 0, 0); */
        color: white;
        margin: 0px;
        border: 0px;
        padding: 10px;
        padding-bottom: 4px;
        text-decoration: none;
        font-size: 20px;
        align-items: center;
        justify-content: center;
        font-family: "PermanentMarker-Regular";
        border-radius: 6px;
        border-top-left-radius: 59px 44px;
        border-top-right-radius: 47px 40px;
        border-bottom-right-radius: 46px 57px;
        border-bottom-left-radius: 46px 36px;
    }

    .calendarStyle {
        position: absolute;
        top: 0;
        right: 0;

        z-index: 2;
        /* display: flex; */
        /* align-items: center; */
        /* justify-content: center; */

        /* width: 50vw; */
        /* height: 50vh; */
        /* z-index: 999; */

        background-color: #ada9ad;

        --praecox-calendar-custom-width: 330px;
        --praecox-calendar-custom-height: 340px;
        --praecox-calendar-custom-inner-width: 310px;
        --praecox-calendar-custom-inner-height: 220px;
        --praecox-calendar-custom-head-height: 48px;
        --praecox-calendar-custom-icon-size: 20px;
        --praecox-calendar-custom-border-radius: 5px;
        --praecox-calendar-custom-font-family: "PermanentMarker-Regular";
        --praecox-calendar-custom-number-font-family: "PermanentMarker-Regular";

        --praecox-calendar-custom-main-color: #ff25d0;
        --praecox-calendar-custom-main-color-hover: #ea00ff;
        --praecox-calendar-custom-main-color-active: #d900e0;
        --praecox-calendar-custom-focused-color: #ff6600;
        --praecox-calendar-custom-adjunctive-color: rgba(255, 85, 212, 0.1);
        --praecox-calendar-custom-secondary-color: rgba(243, 86, 235, 0.2);
        --praecox-calendar-custom-selected-color: #ff00aa;

        --praecox-calendar-custom-weekend-color: rgba(255, 255, 255, 0.6);
        --praecox-calendar-custom-outsidemonth-color: #b2b2c2;

        --praecox-calendar-custom-overbackground-color: #f5f8ff;
        --praecox-calendar-custom-font-main-color: #33004b;
        --praecox-calendar-custom-font-disabled-color: #68686d;
        --praecox-calendar-custom-font-secondary-color: #98989e;

        --praecox-calendar-custom-background: linear-gradient(
            /* Day mode */
                /* 155deg,
            rgba(207,167,255,0.85),
            rgba(222,145,223,0.85) */
                /* Calendar mode */ -20deg,
            rgb(233, 222, 250) 0%,
            rgb(251, 252, 219) 100%
        );
        --praecox-calendar-custom-background-hover: rgba(248, 248, 248, 0.1);
        --praecox-calendar-custom-border: 1px solid #dbdbdb;
        --praecox-calendar-custom-boxshadow: 0px 1px solid #dfdfdf;
    }
</style>
