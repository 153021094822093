<script>
  import HappeningCard from "./HappeningCard.svelte";

  import "leaflet/dist/leaflet.css";
  import L from "leaflet";
  import { onMount } from "svelte";

  export let selectedDates;
  $: {
    markers.forEach((marker) => {
      if (isSelected(marker, selectedDates)) {
        marker.addTo(map);
      } else {
        map.removeLayer(marker);
      }
    });
  }

  // Custom pin icon
  const customPin = L.icon({
    iconUrl: "img/map/pin.png",
    iconSize: [40, 40],
  });

  const customPinBig = L.icon({
    iconUrl: "img/map/pinBig.png",
    iconSize: [60, 60],
  });

  let map;
  let mapRef;
  let cardRef;
  let happenings = [];
  let markers = [];

  function isSelected(marker, selectedDates) {
    let selected = false;
    selectedDates.forEach((tt) => {
      if (
        marker.options.t0 >= tt + 14400000 &&
        marker.options.t0 < tt + 86400000 + 14400000
      ) {
        selected = true;
        return;
      }
    });
    return selected;
  }

  function toggleCard(happening) {
    // Set to preview size when switching to a new card
    // if (cardRef.showCardExtended) {
    //   cardRef.deextendCard();
    // }
    cardRef.setPin(happening);
    cardRef.fmt(happening);
    cardRef.showCard();
    offsetFlyTo(happening);
  }

  // Fly to pin
  function offsetFlyTo(happening) {
    // https://leafletjs.com/examples/zoom-levels/
    let offset = (window.innerHeight / (256 * 2 ** map.getZoom())) * 0.2 * 180;
    map.flyTo([happening.lat - offset, happening.lon], map.getZoom(), {
      animate: true,
      duration: 0.5,
    });
  }

  function handleHash() {
    const hash = window.location.hash.slice(1);
    const marker = markers.find((marker) => marker.options.id === hash);
    if (marker) {
      marker.fire("click");
    }
  }

  function resetMarkerSizes() {
    markers.forEach((marker) => {
      marker.setIcon(customPin);
    });
  }

  onMount(async () => {
    // Get coordinates
    const response = await fetch(`/api/getPins`);
    happenings = await response.json();

    // Create a map
    map = L.map(mapRef, { zoomControl: false }).setView(
      [59.334591, 18.06324],
      13
    );

    // Add a basemap
    L.tileLayer(
      "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
      {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      }
    ).addTo(map);

    // Close card on map click
    map.on("click", () => {
      cardRef.closeCard();
      resetMarkerSizes();
    });

    happenings.forEach((happening) => {
      if (happening.lon != null && happening.lat != null) {
        let tmp = L.marker([happening.lat, happening.lon], {
          icon: customPin,
          id: happening.id,
          t0: happening.t0,
        });
        tmp.addTo(map);
        tmp.on("click", (event) => {
          resetMarkerSizes();
          toggleCard(happening);
          tmp.setIcon(customPinBig);
          window.location.hash = event.target.options.id;
        });
        markers.push(tmp);
      }
    });

    window.onhashchange = handleHash();
  });
</script>

<div class="map" bind:this={mapRef} />
<HappeningCard bind:this={cardRef} />

<style>
  @font-face {
    font-family: "PermanentMarker-Regular";
    src: url(../PermanentMarker-Regular.ttf) format("truetype");
  }

  .map {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
</style>
